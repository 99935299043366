<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicInput from '~/components/form-elements/BasicInput.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import ApiErrorHandler from '~/helpers/api-error-handler'
import { useAuthStore } from '~/store/auth'

const model = defineModel<boolean>()

const props = defineProps<{
  productId: string
}>()

const buttonTitle = 'Создать уведомление'
const emptyForm = { email: null }
const messages = {
  backInStockNotificationIsCreated: 'Уведомление о поступлении создано.',
  emailFieldCannotBeEmpty: 'Email не введён.',
  failedToCreateBackInStockNotification: 'Не удалось создать уведомление о поступлении.',
  failedToGetUser: 'Не удалось получить пользователя.'
}

const { $apiHelper, $toast } = useNuxtApp()
const authStore = useAuthStore()

const form = ref<any>({ ...emptyForm })
const isBackInStockNotificationCreating = ref(false)
const isUserDataLoading = ref(false)
const isUserDataLoadingError = ref(false)

async function beforeOpen (): Promise<void> {
  if (authStore.isUser) {
    await loadUserData()
  }
}

function close (): void {
  model.value = false
}

async function createBackInStockNotification (): Promise<void> {
  const { email } = form.value
  const { backInStockNotificationIsCreated, emailFieldCannotBeEmpty, failedToCreateBackInStockNotification } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  const { productId } = props
  const data = { email, productId }

  isBackInStockNotificationCreating.value = true

  try {
    await $apiHelper.backInStockNotifications.createBackInStockNotification(data)

    $toast.success(backInStockNotificationIsCreated)

    close()
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToCreateBackInStockNotification)
  }

  isBackInStockNotificationCreating.value = false
}

async function loadUserData (): Promise<void> {
  isUserDataLoading.value = true
  isUserDataLoadingError.value = false

  try {
    const userResponse = await $apiHelper.users.getUser(authStore.userId as string)
    form.value.email = userResponse.data.attributes.email || null
  } catch (error) {
    console.error(error)

    isUserDataLoadingError.value = true

    $toast.error(messages.failedToGetUser)
  }

  isUserDataLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" @before-open="beforeOpen">
    <template #title>
      Создание уведомления о&nbsp;поступлении товара
    </template>

    <Preloader v-if="isUserDataLoading" />
    <Notification v-else-if="isUserDataLoadingError" is-small status="error">
      {{ messages.failedToGetUser }}
    </Notification>
    <template v-else>
      <Notification class="mb24" is-small status="info">
        К сожалению, сейчас этого товара нет в&nbsp;наличии. Чтобы мы&nbsp;сообщили о&nbsp;его поступлении, оставьте, пожалуйста, свой email. Срок действия подписки на&nbsp;уведомление —&nbsp;45&nbsp;дней.
      </Notification>

      <BasicInput v-model="form.email" class="mb24" is-required label-text="Email" />

      <BasicButton
        color="green"
        :is-loading="isBackInStockNotificationCreating"
        :title="buttonTitle"
        @click="createBackInStockNotification"
      />

      <Agreement :button-title="buttonTitle" class="mt24" />
    </template>

    <template #action>
      <a href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>
